import { intl } from "@tci18n/vue2";import Ajax from '@/libs/ajax.js';

/**
 * 从 header 中解析文件名
 * @note 默认名称：file
 * @param { Object } headers header 字段
 * @returns { String } 文件名称
 */
function _parseFileName(headers) {
  let fileName = 'file';
  try {
    if (headers['content-disposition']) {
      fileName = decodeURIComponent(headers['content-disposition'].split('filename=')[1]);
    }
  } catch (exception) {
    console.error('Parse download file name error: ', exception.message);
  }
  return fileName;
}


/**
 * 下载文件
 * @param { String } apiHost 下载接口 host
 * @param { String } apiPath 下载接口 path
 * @param { String } httpMethod 下载接口 http method
 * @param { Object } httpData 下载接口参数
 * @param { String } fileName 下载文件名
 */
function downloadFile(apiHost = '', apiPath = '', httpMethod = 'GET', httpData, fileName) {
  return new Promise((resolve, reject) => {
    let params = httpData || {};
    let ajax = new Ajax(apiHost);
    let service = ajax.download(apiPath, httpMethod);
    service(params).then((res) => {
      let rspData = res.data;
      // rspData 为 Blob 对象
      if (rspData.size === 0 || rspData.type !== 'application/vnd.ms-excel' && rspData.type !== 'application/octet-stream') {
        let reader = new FileReader();
        reader.onload = function (e) {
          let result = e.target.result;
          let data = JSON.parse(result);
          reject(new Error(data.message));
        };
        reader.onerror = function (e) {
          reject(new Error(intl.$t("系统异常，请稍后再试")));
        };
        reader.readAsText(rspData, 'utf-8');
      } else {
        if (!fileName) {
          fileName = _parseFileName(res.headers);
        }
        let blob = new Blob([rspData]);
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          let URL = window.URL || window.webkitURL;
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          if (typeof a.download === 'undefined') {
            window.location = objectUrl;
          } else {
            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(objectUrl);
          }
        }
        resolve();
      }
    }).catch((error) => {
      reject(new Error(intl.$t("系统异常，请稍后再试")));
      console.error('Download file error: ', error.message);
    });
  });
}


/**
 * File download
 * @note 支持 HTTP Response Header Content-Type 为 “application/vnd.ms-excel” 和 “application/octet-stream”
 */
export default {
  downloadFile
};