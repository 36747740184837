<template>
<el-cascader :style="{'width': typeof width == 'string' ? width : width + 'px' }" v-model="value" ref="cityCascader"
    :props="childProps" :options="resource" size="small" :disabled="disabled"
    :placeholder="placeholder" :filterable="filterable" :clearable="clearable" 
    :collapse-tags="collapseTag" :show-all-levels="showAllLevels" 
    @change="handleChange">
  </el-cascader>
</template>

<script>
import { intl } from "@tci18n/vue2";export default {
  name: 'LzCascaderCity',
  props: {
    /**
     * @description: 组件宽度
     */
    width: {
      type: [Number, String],
      default: 172
    },
    /**
    * @description: 默认传递选中的值
    */
    selected: {
      type: Array,
      default: () => []
    },
    /**
     * @description: 占位符
     */
    placeholder: {
      type: String,
      default: intl.$t("请选择")
    },
    /**
     * @description: 数据源
     */
    resource: {
      type: Array,
      default: []
    },
    /**
     * @description: 是否支持搜索
     */
    filterable: {
      type: Boolean,
      default: true
    },
    /**
     * @description: 是否禁用
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * @description: 是否支持清空选项
     */
    clearable: {
      type: Boolean,
      default: true
    },
    /**
     * @description: 输入框中是否显示选中值的完整路径
     */
    showAllLevels: {
      type: Boolean,
      default: true
    },
    /**
     * @description: 配置选项,类似Element的props
     */
    childProps: {
      type: Object,
      default: null
    },
    /**
     * @description: 选中数据传递类型，1为选中数组数据，2为getCheckedNodes的节点对象
     */
    callBackDataType: {
      type: Number,
      default: 1
    }

  },
  data() {
    return {
      value: this.selected,
      collapseTag: this.childProps && this.childProps.multiple || false,
      checkedNodes: [],
      callBackType: this.callBackDataType
    };
  },
  created() {
  },
  watch: {
    selected: function (oldVal, newVal) {
      console.log(oldVal);
      this.value = oldVal;
    }
  },
  methods: {
    handleChange: function (data) {
      if (data.length === 0) {
        this.$refs['cityCascader'].$refs.panel.activePath = []; //清除下拉选中的遗留记录
        this.$refs['cityCascader'].$refs.panel.clearCheckedNodes(); //清空选中的内容
        this.$emit('lzCascaderChange', []);
        return false;
      }

      if (this.callBackType === 1) {
        let arr = [];
        if (this.childProps && this.childProps['label-in-value'] && !this.childProps.multiple) {
          if (data.length > 0) {
            data.forEach((item) => {
              arr.push(this.arrGetLabel(this.resource, item));
            });
          }
        } else
        {
          arr = data;
        }
        this.$emit('lzCascaderChange', arr);
      } else
      if (this.callBackType === 2) {
        //解决reomve-tag时getCheckedNodes依然返回未勾选数据的问题
        this.$nextTick(() => {
          this.$emit('lzCascaderChange', this.$refs['cityCascader'] && this.$refs['cityCascader'].getCheckedNodes());
        });
      }
    },
    arrGetLabel: function (data, id) {
      let value = this.childProps['value'] || 'value';
      let children = this.childProps['children'] || 'children';

      for (const item of data) {
        if (item[value] === id) {
          return item;
        }

        if (item[children] && item[children].length > 0) {
          const _item = this.arrGetLabel(item[children], id);
          if (_item) return _item;
        }
      }
    },
    clearCheckNodes: function () {
      try {
        this.$refs['cityCascader'].$refs.panel.clearCheckedNodes();
        this.$refs['cityCascader'].$refs.panel.activePath = []; //设置为空可以让节点不高亮显示
      }
      catch (err) {
      }
    }
  }
};
</script>

<style lang="scss">
//重置element-cascader的样式，已接近业务板块ui规范
  $theme-color: #497cf6;
  $text-color: #474f64;
  $background-color: #edf3ff;
  $input-color: #6d96f8;
  .el-cascader {
    color: $text-color;
    &:not(.is-disabled):hover {
      .el-input__inner {
        border-color: $input-color
      }
    }
    .el-cascader__search-input {
      margin: 2px 0 2px 7px !important;
      font-size: 14px;
      height: 20px;
    }
    .el-input__prefix, 
    .el-input__suffix {
      color: $text-color !important;
    }
    .el-input {
      &.is-focus {
        .el-input__inner {
          border-color: $input-color;
          box-shadow: 0 0 0 2px rgba(73, 124, 246, 0.2);
        }
      }
      .el-input__inner {
        padding: 0 7px !important;
        border-radius: 2px;
        font-size: 14px;
      }
      .el-input__inner:focus {
        border-color: $input-color;
      }
    }
  }
</style>
