<template>
<div class="box-view">
    <div class="lump-item" v-for="(item, index) in datas" :key="index">
      <div :style="{ 'background-color': item.color }" class="cube"></div>
      <label>{{item.title}}</label>
    </div>
  </div>
</template>

<script>
/**
 * 提示色块视图
 */
export default {
  name: 'ColorLumpTips',
  props: {
    datas: Array
  },
}
</script>

<style lang="scss" scoped>
.box-view {
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
  }

  .lump-item {
    line-height: 1;
    display: inline-block;
    vertical-align: top;

    .cube {
      width: 12px;
      height: 12px;
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle;
    }

    label {
      font-size: 12px;
      color: #696969;
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
    }

    & + & {
      margin-left: 12px;
    }
  }
</style>
